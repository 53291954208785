import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'




const BlockeMensaje = ({image}) => {
    const { rpdata } = useContext(GlobalDataContext)


    return (
        <div
            className='py-[150px] bg-cover bg-no-repeat bg-fixed bg-center relative '
            style={{ backgroundImage: `url("${image ? image : rpdata?.stock?.[0]}")` }}
        >
            <div className='text-center text-[17px] text-[#303030]  w-4/5 m-auto backdrop-sepia-0 bg-white/30 px-10 py-5 font-[450] font-Calibri lg:w-1/2 '>

                <h2 className='px-10'>Fuel Your Body, Fuel Your Life</h2>
        
            <p className='pl-3'>My specialty is teaching people how they can lead a healthy life.
            the most important thing is that it is cheaper than eating any other breakfast. the best thing about it, is that you get results quickly. for me this is what matters the most that you are satisfied and happy, even many of our customers have decided to do business with us because of the quality and effectiveness of our products.</p>

            <p className='pl-3'>
            You too can have a healthy and successful business as many of our customers are experiencing. I myself am the result of this and today I am very happy and grateful.
            </p>
            </div>
           
            
        </div>
    )
}

export default BlockeMensaje